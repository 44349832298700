"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Promise = window.Promise; // See: https://html.spec.whatwg.org/multipage/webappapis.html#the-hostpromiserejectiontracker-implementation

var unhandledReasons = [];
var unhandledRejections = [];
window.addEventListener('unhandledrejection', function (e) {
  unhandledRejections.push(e.promise);
  unhandledReasons.push(e.reason);
});
window.addEventListener('rejectionhandled', function (e) {
  var promise = e.promise;
  var at = unhandledRejections.indexOf(promise);

  if (at !== -1) {
    unhandledRejections.splice(at, 1);
    unhandledReasons.splice(at, 1);
  }
});
var Q = {
  Promise: Promise,
  getUnhandledReasons: function getUnhandledReasons() {
    return unhandledReasons.slice();
  }
}; // global Q is used by Reagan/react-rhumb and through the console by devs

window.Q = Q;
var _default = Q;
exports.default = _default;
module.exports = exports.default;