"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  pathRegex: /^\/(?:[A-Za-z0-9-_]*)\/(\d+)(?:\/|$)/,
  queryParamRegex: /(?:\?|&)portalid=(\d+)/i,
  getPortalIdFromPath: function getPortalIdFromPath(regex) {
    if (document) {
      if (regex == null) {
        regex = this.pathRegex;
      }

      return this.parsePortalIdFromString(document.location.pathname, regex);
    }
  },
  getPortalIdFromQueryParam: function getPortalIdFromQueryParam() {
    if (document) {
      return this.parsePortalIdFromString(document.location.search, this.queryParamRegex);
    }
  },
  parsePortalIdFromString: function parsePortalIdFromString(string, regex) {
    var idRe = regex.exec(string);
    var portalId = idRe != null ? idRe[1] : undefined;
    return +portalId || undefined;
  },
  getCookie: function getCookie(name) {
    if (typeof document !== 'undefined' && document !== null) {
      var parts = document.cookie.split(name + "=");

      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
    }
  },
  getPortalIdFromCookie: function getPortalIdFromCookie() {
    if (document && /hubspotqa\.com/.test(document.location.host)) {
      return undefined;
    }

    var cookiePortal = this.getCookie('hubspot.hub.id');

    if (cookiePortal != null ? cookiePortal.length : undefined) {
      if (window.newrelic) {
        window.newrelic.setCustomAttribute('gotPortalIdFromCookie', true);
      }

      return +cookiePortal;
    } else {
      return undefined;
    }
  },
  get: function get(options) {
    if (options == null) {
      options = {};
    }

    if (this.id && !options.reparse) {
      return this.id;
    }

    var id = this.getPortalIdFromPath(options.regex) || this.getPortalIdFromQueryParam() || (options.ignoreCookie ? undefined : this.getPortalIdFromCookie());

    if (!options.preserveGlobalId) {
      if (window.hubspot == null) {
        window.hubspot = {};
      }

      if (window.hubspot.portal == null) {
        window.hubspot.portal = {};
      }

      if (window.hubspot.portal.id == null) {
        window.hubspot.portal.id = id;
      }

      this.id = id;
    }

    return id;
  }
};
exports.default = _default;
module.exports = exports.default;